<template>
  <PageNavbar :showLogout="false" />
  <LoginLanding v-if="enableLandingPage" :title="title" />
  <div v-else>
    <div :id="(config.subsmgmt.enableQrCodeLogin && 'divided') || ''">
      <div class="container-fluid">
        <div class="row justify-content-center align-items-stretch pt-2 pb-5">
          <!-- TODO: make little tabs at the top right coner -->
          <!-- https://getbootstrap.com/docs/5.3/layout/grid/#grid-options -->
          <LoadingComponent v-if="state.showSigninSpinner"/>
          <SignInCard :showBody="!state.showSigninSpinner" class="col-lg-4 px-1 py-5 text-center" style="width: 344px" />
          <QrCodeCard
            v-if="!state.showSigninSpinner && config.subsmgmt.enableQrCodeLogin"
            class="col-lg-4 px-1 py-5 text-center"
            style="width: 344px"
          />
        </div>
      </div>
    </div>
  </div>
  <PageFooter v-if="!state.showSigninSpinner"/>
</template>

<script setup lang="ts" charset="utf-8">
import { reactive, ref } from 'vue';
import { LocationQueryValue, useRoute } from 'vue-router';

import PageNavbar from '@/components/PageNavbar.vue';
import PageFooter from '@/components/PageFooter.vue';
import SignInCard from '@/components/SignInCard.vue';
import LoginLanding from '@/components/LoginLanding.vue';
import QrCodeCard from '@/components/QrCodeCard.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';

import { config } from '@/config';

const route = useRoute();

const redirectQuery = (() => {
  const redirect = route.query.redirect as LocationQueryValue;
  if (!redirect) return {};

  const url = new URL(redirect, window.location.origin);
  return Object.fromEntries(url.searchParams);
})();

const flow = (() => {
  if (!redirectQuery) return 'login';

  return redirectQuery.flow ?? 'login';
})() as 'login' | 'register';
const title = flow === 'register' ? 'Sign In to register your account!' : 'Sign In';
const enableLandingPage = ref(config.subsmgmt.enableLandingPage);

// TODO: dedup the same code from SignIncard
const hide = redirectQuery.hide
const signin = redirectQuery.signin;

const state = reactive<{
  appleSigninEnabled: boolean,
  showSigninSpinner: boolean,
}>({
  appleSigninEnabled: hide !== 'apple' && config.oidc.apple.enabled,
  showSigninSpinner: signin === 'apple' || signin === 'google'
});
</script>

<style scoped media="screen">
#divided {
  .card {
    border: 0;
  }

  @media (max-width: 704px) {
    .card:first-child {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .card:last-child::after {
      content: '';
      position: absolute;
      left: 10%;
      width: 80%;
      border-bottom: 1px solid #d7d7d7;
    }
    .card:last-child {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  @media (min-width: 704px) {
    .card:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .card:last-child::after {
      content: '';
      position: absolute;
      top: 20%;
      height: 60%;
      border-right: 1px solid #d7d7d7;
    }
    .card:last-child {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
</style>
