<template>
  <div class="card">
    <div class="card-body">
      <DataTable :value="deviceSessionData" responsiveLayout="scroll" class="p-datatable-sm">
        <Column>
          <template #body="slotProps">
            <LinkIcon v-if="slotProps.data.type === 'linked'" />
            &nbsp;
            <RouterIcon v-if="slotProps.data.role === 'router'" />
            &nbsp;
          </template>
        </Column>
        <Column header="Name" :sortable="true">
          <template #body="{data: {friendlyName: nickname}}: { data: DeviceSessionData }">
            <span v-if="nickname">{{ nickname }}</span>
            <span v-else style="color: gray">untitled</span>
          </template>
        </Column>
        <Column field="platform" header="Platform" :sortable="true" />
        <Column field="lastActive" header="Last Active" :sortable="true" />
        <Column
          headerStyle="width: 4rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <div data-bs-toggle="tooltip" data-bs-placement="top" :title="slotProps.data.uuid">
              <InfoIcon />
            </div>
          </template>
        </Column>
        <Column
          headerStyle="width: 4rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <FlatGarbageButtonRed
              v-if="slotProps.data.type === 'linked'"
              @click="handleRevokeDeviceClick(slotProps.data.uuid, slotProps.data.friendlyName)"
            />
            <FlatGarbageButtonRed
              v-if="slotProps.data.type === 'active'"
              @click="
                handleRevokeActiveDeviceClick(slotProps.data.uuid, slotProps.data.friendlyName)
              "
            />
          </template>
        </Column>
        <Column
          headerStyle="width: 4rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps: { data: DeviceSessionData }">
            <FlatEditButton
              v-if="slotProps.data.type === 'linked'"
              @click="handleRenameClick(slotProps.data.uuid, slotProps.data.friendlyName || null)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { difference } from 'ramda';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { Tooltip } from 'bootstrap';

import { LinkedDevice, ActiveDevice } from '@/model';
import FlatEditButton from '@/components/buttons/FlatEditButton.vue';
import FlatGarbageButtonRed from '@/components/buttons/FlatGarbageButtonRed.vue';
import LinkIcon from '@/components/icons/LinkIcon.vue';
import InfoIcon from '@/components/icons/InfoIcon.vue';
import RouterIcon from '@/components/icons/RouterIcon.vue';

const props = defineProps<{
  activeDevices: readonly ActiveDevice[];
  linkedDevices: readonly LinkedDevice[];
}>();
defineSlots<{ body(props: { data: DeviceSessionData }): any }>();

type DeviceSessionData = Pick<ActiveDevice, 'platform' | 'friendlyName' | 'role'> & {
  lastActive: string | undefined; // why not Date.toLocaleString()?
  type: 'linked' | 'active';
  uuid: string;
}

onMounted(() => {
  // seems like there's an issue with changing tooltip attrs with bootstrap
  // so we use vanilla js
  // https://stackoverflow.com/questions/72695891/bootstrap-5-0-2-tooltip-data-bs-delay-attribute-problem
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function(tooltipTriggerEl) {
    // Can also use different show and hides like { delay: { show: 100, hide: 1000 } }
    // https://getbootstrap.com/docs/5.3/components/tooltips/#options
    return new Tooltip(tooltipTriggerEl, { delay: 100 });
  });
});

const allActiveUUIDs = computed<string[]>(() => props.activeDevices.map(d => d.deviceUuid));
const allActiveLinkedDeviceUUIDs = computed<string[]>(() =>
  props.linkedDevices.map(d => d.deviceUUID),
);
const activeNonLinkedDevices = computed<string[]>(() =>
  difference(allActiveUUIDs.value, allActiveLinkedDeviceUUIDs.value),
);

const deviceSessionData = computed(() => {
  const linkedDeviceSessionData: DeviceSessionData[] = props.linkedDevices.map(d => {
    // The linked device's last active time is really stored in the active devices table
    const lastActive =
      props.activeDevices.find(a => a.deviceUuid === d.deviceUUID)?.loginTimestamp ||
      d.lastActiveTime;

    return {
      platform: d.platform || null,
      friendlyName: d.friendlyName,
      uuid: d.deviceUUID,
      lastActive: lastActive instanceof Date ? lastActive.toISOString() : lastActive,
      type: 'linked',
      role: d.role,
    };
  });
  const activeDeviceSessionData: DeviceSessionData[] = props.activeDevices
    .filter(d => activeNonLinkedDevices.value.includes(d.deviceUuid))
    .map(d => ({
      platform: d.platform || null,
      friendlyName: d.friendlyName || null,
      uuid: d.deviceUuid,
      lastActive: d.loginTimestamp,
      type: 'active',
      role: null,
    }));

  return [...linkedDeviceSessionData, ...activeDeviceSessionData];
});

const emit = defineEmits(['handleShowModal']);

const handleRenameClick = (deviceUUID: string, currentNickname: string | null) => {
  emit('handleShowModal', 'renameDeviceModal', deviceUUID, currentNickname);
};

const handleRevokeActiveDeviceClick = (deviceUUID: string, friendlyName: string) => {
  emit('handleShowModal', 'revokeActiveDeviceModal', deviceUUID, friendlyName);
};

const handleRevokeDeviceClick = (deviceUUID: string, friendlyName: string) => {
  emit('handleShowModal', 'revokeDeviceModal', deviceUUID, friendlyName);
};
</script>
