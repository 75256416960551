<template>
  <svg
    id="Layer_1"
    version="1.1"
    class="speedify-logo"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2129.8 519.6"
    style="enable-background: new 0 0 2129.8 519.6"
    height="45"
    xml:space="preserve"
  >
    <g id="Layer_2_1_">
      <g id="Layer_1-2">
        <g id="Speedify">
          <path
            class="st0"
            d="M849.6,181.6L831.9,196c-6.8-19.5-17.1-33.6-30.6-42.4s-31.5-13.2-53.7-13.1c-15.9,0-29.8,2.5-41.5,7.4
                    s-20.8,11.6-26.9,20.1c-6.1,8.3-9.4,18.4-9.2,28.8c0,10.4,2.6,19.1,7.8,25.9s14,12.7,26.5,17.5c12.5,4.8,29.7,9.1,51.6,13
                    c35.4,6,60.5,14.8,75.3,26.3s22.2,27.4,22.2,47.7c0.2,13.9-4.3,27.6-12.8,38.7c-8.5,11.2-20.5,19.9-36,26.1
                    c-15.5,6.2-33.2,9.2-53.2,9.2c-28.5,0-52.6-5.7-72.2-17.1s-33-27.4-40.1-48l18.1-14c5.5,19.5,16.5,34.4,33.1,44.6
                    c16.6,10.3,37.1,15.4,61.5,15.4c23.6,0,42.8-4.8,57.8-14.4s22.4-22.8,22.4-39.5c0-14.5-6.1-26-18.3-34.5s-33.4-15.4-63.6-20.6
                    c-25.5-4.4-45.6-9.7-60.5-16c-14.8-6.3-25.4-14.1-31.9-23.4c-6.5-9.3-9.7-21-9.7-34.9c-0.1-13.8,4.2-27.3,12.1-38.7
                    c8.1-11.8,19.6-21.2,34.5-28.2c14.9-7,32.4-10.5,52.4-10.5C798.1,121.6,832.3,141.6,849.6,181.6z"
          />
          <path
            class="st0"
            d="M1052.9,215.8c16.9,18.9,25.3,45.1,25.3,78.6s-8.4,59.6-25.3,78.5c-16.9,18.9-39.8,28.4-68.9,28.4
                    c-17.5,0-33-4.2-46.3-12.7c-13.2-8.4-23.4-20.6-29.4-35v130h-20.6v-292h18.5l1.2,46.5c5.8-15.9,15.3-28.3,28.6-37.2
                    c13.3-8.9,29.3-13.4,47.9-13.4C1013.1,187.4,1036.1,196.8,1052.9,215.8z M1037.5,359.5c13.4-15.8,20.1-37.5,20.2-65.2
                    c0-27.7-6.7-49.4-20.2-65.2c-13.4-15.8-32.1-23.6-55.9-23.6c-21.4,0-38.7,7.3-51.8,21.8s-20.3,34.4-21.4,59.6v14.8
                    c1.1,25.2,8.2,45.1,21.4,59.6s30.4,21.8,51.8,21.8C1005.4,383.1,1024.1,375.3,1037.5,359.5z"
          />
          <path
            class="st0"
            d="M1285.9,303h-163.3c1.4,25.2,8.8,44.9,22.4,59s31.7,21.2,54.5,21.2c16.2,0,30.1-3.3,41.7-9.9
                    c11.3-6.2,20.2-16.1,25.3-28l17.3,9c-7.2,14.6-18.7,26.7-32.9,34.8c-14.3,8.1-31.4,12.1-51.4,12.1c-30.4,0-54.2-9.5-71.3-28.4
                    c-17.1-18.9-25.7-45.1-25.7-78.5c0-33.4,8.3-59.6,24.9-78.6s39.8-28.4,69.7-28.4c28,0,49.9,9.3,65.8,28s23.8,43.3,23.9,74
                    C1286.7,293.9,1286.5,298.5,1285.9,303z M1144.4,226.3c-12.9,13.8-20.2,33.2-21.8,58.2h144.8c-1.4-24.7-8.2-44-20.4-58
                    s-28.9-21-50-21C1174.8,205.5,1157.3,212.4,1144.4,226.3L1144.4,226.3z"
          />
          <path
            class="st0"
            d="M1493.2,303h-163.3c1.4,25.2,8.8,44.9,22.4,59s31.7,21.2,54.5,21.2c16.2,0,30.1-3.3,41.7-9.9
                    c11.3-6.2,20.2-16.1,25.3-28l17.3,9c-7.2,14.6-18.7,26.7-32.9,34.8c-14.3,8.1-31.4,12.1-51.4,12.1c-30.4,0-54.2-9.5-71.3-28.4
                    c-17.1-18.9-25.7-45.1-25.7-78.5c0-33.4,8.3-59.6,24.9-78.6s39.8-28.4,69.7-28.4c28,0,49.9,9.3,65.8,28
                    c15.9,18.7,23.8,43.3,23.9,74C1494,293.9,1493.7,298.5,1493.2,303z M1351.7,226.3c-12.9,13.8-20.2,33.2-21.8,58.2h144.8
                    c-1.4-24.7-8.2-44-20.4-58c-12.2-14-28.9-21-50-21C1382.1,205.5,1364.6,212.4,1351.7,226.3L1351.7,226.3z"
          />
          <path
            class="st0"
            d="M1707.4,96.9v300.2h-18.5l-1.2-46.1c-5.3,15.1-15.4,28-28.8,36.8c-13.4,8.9-29.3,13.4-47.7,13.4
                    c-29.1,0-52-9.5-68.9-28.4c-16.9-18.9-25.3-45.1-25.3-78.5c0-33.4,8.4-59.6,25.3-78.6s39.8-28.4,68.9-28.4
                    c17.8,0,33.3,4.2,46.5,12.8c13.1,8.4,23.3,20.6,29.2,35V96.9H1707.4z M1665.4,361.6c13.2-14.4,20.3-33.9,21.4-58.6v-17.3
                    c-1.4-24.7-8.6-44.2-21.6-58.6s-30.2-21.6-51.6-21.6c-23.9,0-42.5,7.9-55.9,23.6c-13.4,15.8-20.1,37.5-20.2,65.2
                    c0,27.7,6.7,49.4,20.2,65.2c13.4,15.8,32.1,23.6,55.9,23.6C1635,383.1,1652.3,375.9,1665.4,361.6L1665.4,361.6z"
          />
          <path
            class="st0"
            d="M1749.6,146.9c-3.1-3.2-4.7-7.3-4.7-12.6c0-5.2,1.6-9.4,4.7-12.5s7.3-4.7,12.6-4.7s9.4,1.6,12.5,4.7
                    s4.7,7.3,4.7,12.5s-1.6,9.4-4.7,12.6s-7.3,4.7-12.5,4.7S1752.7,150,1749.6,146.9z M1772.4,397.1h-20.6V191.5h20.6V397.1z"
          />
          <path
            class="st0"
            d="M1857.9,191.5h72.4v17.7h-72.4v187.9h-20.6V209.2h-41.1v-17.7h41.1V157c0-19.2,5.3-34.3,16-45.4
                    c10.7-11.1,25.7-16.7,45.2-16.7c14.8,0,25.9,3.7,33.3,11.1l-5.8,16.4c-6.9-6-15.5-9-25.9-9c-13.4,0-23.8,3.9-31.2,11.7
                    s-11.1,19.1-11.1,33.8V191.5z"
          />
          <path
            class="st0"
            d="M2030.7,435c-7.7,18.7-16.2,31.8-25.5,39.3c-9.3,7.5-21.1,11.3-35.4,11.3c-17,0-30-5.1-39.1-15.2l7.8-17.7
                    c6.6,9.9,17,14.8,31.2,14.8c9.6,0,17.8-2.7,24.5-8s13-15.2,18.7-29.5l13.2-32.9l-21.4-51l-65.4-154.6h23.4l62.9,155.9l10.3,27.6
                    l11.1-29.6l60.5-153.8h22.2L2030.7,435z"
          />
        </g>
        <g>
          <circle class="st1" cx="259.8" cy="259.8" r="259.8" />
          <line class="st2" x1="284.9" y1="183.9" x2="363.3" y2="183.9" />
          <line class="st2" x1="246.1" y1="96.4" x2="287.9" y2="96.4" />
          <line class="st2" x1="266" y1="139.8" x2="325.9" y2="139.8" />
          <line class="st2" x1="247" y1="183.9" x2="247" y2="183.9" />
          <path
            class="st2"
            d="M293.9,297.1c22.1,10.1,29,38.6,17.2,59.9c-5,9.1-13.1,16.1-22.8,19.8"
          />
          <path
            class="st2"
            d="M204.6,102.7c-14.9,8.4-27.2,20.8-35.4,35.8c-24.7,44.7-10.5,105,36.2,125.8"
          />
          <line class="st2" x1="234.7" y1="335.7" x2="156.3" y2="335.7" />
          <line class="st2" x1="231.7" y1="423.2" x2="273.5" y2="423.2" />
          <line class="st2" x1="253.6" y1="379.8" x2="193.7" y2="379.8" />
          <line class="st2" x1="272.6" y1="335.7" x2="272.6" y2="335.7" />
          <path
            class="st2"
            d="M314.2,255.3c46.5,21.3,61,81,36.2,125.8c-8.3,15-20.5,27.4-35.4,35.8"
          />
          <path
            class="st2"
            d="M231.2,142.7c-9.7,3.7-17.7,10.7-22.7,19.8c-11.8,21.3-4.9,49.7,17.2,59.9"
          />
          <path class="st2" d="M236.6,276.1c0,0,20.1,7.2,26,9.2" />
          <path class="st2" d="M256,234.1l25,8.6" />
        </g>
      </g>
    </g>
  </svg>
</template>
