<template>
  <div v-if="userStore.routerSubscriptions.length > 0" class="mb-5">
    <div class="d-flex justify-content-between align-items-end mb-3">
      <div class="col-lg-6">
        <h3 class="my-2">Associated Router Subscriptions</h3>
        <small>You may choose to associate any of your router subscriptions to your team.</small>
      </div>
    </div>
    <div
      v-for="routerSub in userStore.routerSubscriptions"
      :key="routerSub.referenceId"
      class="form-check form-switch"
      :style="state.pending ? 'padding-left: 0': ''"
    >
      <input
        v-if="!state.pending"
        :id="routerSub.productName"
        class="form-check-input"
        type="checkbox"
        :checked="userStore.teamRouterAssociations[referenceId as string].includes(routerSub.referenceId)"
        @click.prevent="toggleTeamRouterAssociation(routerSub.referenceId, $event)"
      />
      <div
        v-if="state.pending"
        class="spinner-border spinner-border-sm"
        role="status"
        style="margin-right: 1.5em;"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <label class="form-check-label" :for="routerSub.productName">
        {{
          routerSub.productName
            .split('.')
            .map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
            .replace(/gb/i, 'GB')
        }}
      </label>
    </div>
  </div>
</template>

<script setup lang='ts'>
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router';

import { useUserStore } from '@/store/user';
import { Subscription } from '@/model';
import { associateTeamWithRouterSub, dissociateTeamFromRouterSub } from '@/services/backend';

const userStore = useUserStore();
const route = useRoute();
const { referenceId } = route.query;

const state = reactive({
  pending: false,
});

const subscription = computed<Subscription>(
  () => userStore.subscriptions.find(s => s.referenceId === referenceId)!,
);

const toggleTeamRouterAssociation = async (routerSubReferenceId: string, event: any) => {
  if (event.target.checked) {
    await associateRouter(routerSubReferenceId);
  } else {
    await dissociateRouter(routerSubReferenceId);
  }
};

const associateRouter = async (routerSubReferenceId: string) => {
  state.pending = true;
  const teamId = subscription.value.team!.teamId;
  await associateTeamWithRouterSub(teamId, routerSubReferenceId);
  state.pending = false;
};

const dissociateRouter = async (routerSubReferenceId: string) => {
  state.pending = true;
  const teamId = subscription.value.team!.teamId;
  await dissociateTeamFromRouterSub(teamId, routerSubReferenceId);
  state.pending = false;
};
</script>
