<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->

  <!-- seat modification modal -->
  <div
    v-if="state.showModifySeatsModal"
    id="modifySeatsModal"
    class="modal fade"
    data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modifySeatsLabel" aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="modifySeatsLabel" class="modal-title">Add/Remove Seats</h5>
          <button
            type="button"
            class="btn-close stm-btn-modify-seats-cancel"
            data-bs-dismiss="modal" aria-label="Close"
            @click="dismisschangeQuantityModal()"
          />
        </div>
        <div class="modal-body">
          <div class="alert alert-primary" role="alert">
            Adding additional seats will be billed ${{ seatCost!.price }} per {{ seatCost!.cycle }}. If seats are removed, your account will receive a
            prorated account credit per seat.
          </div>
          <form class="form-floating" @submit.prevent="changeSubscriptionQuantity">
            <input
              id="changeSeatsInput"
              v-model="state.updatedQuantity.quantity"
              type="number"
              class="form-control"
              :placeholder="subscription.quantity.toString()"
              :min="subscription.team!.teamMembers.length"
            />
            <label for="changeSeatsInput">Change quantity of seats (min: {{ subscription.team!.teamMembers.length }})</label>
            <small
              v-if="
                v$.updatedQuantity.quantity.required.$invalid
                || v$.updatedQuantity.quantity.integer.$invalid
                || v$.updatedQuantity.quantity.minValue.$invalid
              "
              style="color: red;"
            >
              Please enter a valid integer larger than the minimum ({{ subscription.team!.teamMembers.length }})
            </small>
          </form>
        </div>
        <div class="modal-footer">
          <div v-if="state.updatedQuantity.success" class="alert alert-success stm-msg-modify-seats-success" role="alert">
            Your team's seat count has been updated! Your account will reflect this change in a few minutes.
          </div>
          <div v-if="state.updatedQuantity.failure" class="alert alert-danger stm-msg-modify-seats-error" role="alert">
            <!-- eslint-disable-next-line max-len -->
            <i class="fas fa-exclamation-circle"></i>&nbsp;Something went wrong when updating your team's seat count. Please try again later or contact support@speedify.com
            <hr>
            {{ state.updatedQuantity.errorContext }}
          </div>
          <button
            v-if="!state.updatedQuantity.success"
            type="button"
            class="btn btn-secondary stm-btn-modify-seats-cancel"
            data-bs-dismiss="modal"
            @click="dismisschangeQuantityModal()"
          >
            Cancel
          </button>
          <button
            v-if="!state.updatedQuantity.success"
            type="submit"
            class="btn btn-speedify-blue stm-btn-modify-seats-confirm"
            :class="{
              disabled: isNaN(state.updatedQuantity.quantity)
                || state.updatedQuantity.quantity < subscription.team!.teamMembers.length
                || state.updatedQuantity.quantity === subscription.quantity
                || v$.updatedQuantity.quantity.required.$invalid
                || v$.updatedQuantity.quantity.integer.$invalid
                || v$.updatedQuantity.quantity.minValue.$invalid
            }"
            @click="changeSubscriptionQuantity()"
          >
            <span v-if="state.updatedQuantity.pending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Change Seats Quantity
          </button>
          <button
            v-if="state.updatedQuantity.success"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="dismisschangeQuantityModal()"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="subscription.type === 'teams' || subscription.type === 'families'" class="row mb-4">
    <div class="col-sm">
      <div class="card h-100">
        <div class="card-body pb-4">
          <div class="card-title">
            <small>Seats</small>
          </div>
          <h5>{{ subscription.quantity }}</h5>
          <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
          <a
            v-if="subscription.type === 'teams' && isSubscriptionOwner"
            type="button"
            class="text-decoration-none stm-btn-modify-seats"
            @click="handleShowModal('modifySeatsModal')"
          >
            <small>Add/Remove Seats</small>
          </a>
        </div>
      </div>
    </div>
    <div class="col-sm">
      <div class="card h-100">
        <div class="card-body pb-4">
          <div class="card-title">
            <small>Assigned Seats</small>
          </div>
          <h5>{{ subscription.team!.teamMembers.length }}</h5>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import { computed, reactive, ref, onMounted } from 'vue';
import useVuelidate from '@vuelidate/core';
import {
  required,
  minValue,
  integer,
} from '@vuelidate/validators';

import { useUserStore } from '@/store/user';
import { changeQuantity, getPricingInfo } from '@/services/backend';
import { showModal } from '@/services/modal';
import { Subscription } from '@/model';

const props = defineProps<{
  subscription: Subscription;
}>();

const userStore = useUserStore();
const { email } = userStore;
const state = reactive({
  updatedQuantity: {
    quantity: props.subscription.quantity,
    success: false,
    failure: false,
    errorContext: '',
    pending: false,
  },
  showModifySeatsModal: false,
});

const isSubscriptionOwner = computed(() => props.subscription.ownerEmail === email);

// show any modal with element ID by toggling the `show`Element state
const handleShowModal = (elementID: string) => {
  // @ts-ignore
  state[`show${elementID.charAt(0).toUpperCase()}${elementID.slice(1)}`] = true;
  showModal(elementID);
};

const dismisschangeQuantityModal = () => {
  state.updatedQuantity.quantity = props.subscription.quantity;
  state.updatedQuantity.success = false;
  state.updatedQuantity.failure = false;
  state.updatedQuantity.errorContext = '';
  state.updatedQuantity.pending = false;
  state.showModifySeatsModal = false;
};

const changeSubscriptionQuantity = async () => {
  state.updatedQuantity.pending = true;
  const result = await changeQuantity(props.subscription.referenceId, state.updatedQuantity.quantity);
  state.updatedQuantity.pending = false;
  if (result.error) {
    state.updatedQuantity.failure = true;
    // @ts-ignore
    state.updatedQuantity.errorContext = result.response;
    return;
  }
  state.updatedQuantity.success = true;
  userStore.updateSeatQuantity(props.subscription.referenceId, state.updatedQuantity.quantity);
};

const seatCost = ref<{ price: number, cycle: 'month' | 'yearly' } | null>(null);
onMounted(async () => {
  const pricing = await getPricingInfo();
  if (props.subscription.productName?.match(/monthly/)) {
    seatCost.value = { price: pricing['speedify-teams-unlimited-monthly'], cycle: 'month' };
  }

  if (props.subscription.productName?.match(/yearly/)) {
    seatCost.value = { price: pricing['speedify-teams-unlimited-yearly'], cycle: 'yearly' };
  }
});

const rules = computed(() => ({
  updatedQuantity: {
    quantity: {
      required,
      integer,
      minValue: minValue(props.subscription.team!.teamMembers.length),
    },
  },
}));

const v$ = useVuelidate(rules, state);
</script>
