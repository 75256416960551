<template>
  <div
    id="renameDeviceModal"
    class="modal fade"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="renameDeviceModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="renameDeviceModal" class="modal-title">Rename Device</h5>
          <button
           type="button"
           class="btn-close stm-btn-rename-device-cancel"
           data-bs-dismiss="modal"
           aria-label="Close"
           @click="dismissModal"
          />
        </div>
        <form @submit.prevent="handleRenameDeviceClick">
          <div class="modal-body">
            <div class="form-floating">
              <input
                id="nicknameInput"
                v-model="state.nickname"
                type="nickname"
                maxLength="64"
                class="form-control" placeholder="Nickname" aria-label="Nickname"
                aria-describedby="nickname" @input="changeNicknameOnChangeHandler"
              />
              <label for="currentNicknameInput">
                New Nickname
              </label>
            </div>
          </div>
          <div
            v-if="state.failed"
            class="alert alert-danger mt-3 stm-msg-rename-device-error"
            role="alert"
          >
            <i class="fas fa-exclamation-circle"></i>&nbsp;Error: unable to rename device
            <hr>
            {{ state.failureError }}
          </div>
          <div
            v-if="state.success"
            class="alert alert-success mt-3 stm-msg-rename-device-success"
            role="alert"
          >
            <i class="fas fa-check-circle"></i>&nbsp;Device has been successfully renamed!
          </div>
          <div class="modal-footer">
            <button
              v-if="state.success"
              type="button"
              class="btn btn-speedify-blue stm-btn-rename-device-done"
              data-bs-dismiss="modal"
              @click="dismissModal"
            >
             Done
            </button>
            <button
             v-if="!state.success"
             type="submit"
             class="btn btn-speedify-blue stm-btn-rename-device-confirm"
             :class="{
               'disabled': v$.state.nickname.required.$invalid
             }"
            >
             Rename Device
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { renameLinkedDevice } from '@/services/backend';

const props = defineProps<{
  uuid: string,
  nickname: string | null,
}>();
const emit = defineEmits(['getLinkedDevices', 'dismissModal']);

const state = reactive({
  success: false,
  failed: false,
  failureError: {},
  nickname: '',
});
const handleRenameDeviceClick = async () => {
  if (state.nickname === props.nickname) {
    state.success = false;
    state.failed = true;
    state.failureError = 'Device name cannot be the same';
    return;
  }

  const response = await renameLinkedDevice(props.uuid, state.nickname);
  if (response.error) {
    state.success = false;
    state.failed = true;
    state.failureError = response.message;
  } else {
    state.failed = false;
    state.success = true;
    emit('getLinkedDevices');
  }
};

const changeNicknameOnChangeHandler = () => {
  state.success = false;
  state.failed = false;
  state.failureError = '';
};

const dismissModal = () => {
  emit('dismissModal');
  state.nickname = '';
}

const rules = {
  state: {
    nickname: { required },
  },
}
const v$ = useVuelidate(rules, { state });
</script>
