<template>
  <div class="container-fluid">
    <div class="router-card">
      <h4 class="fw-bold">Your {{ vendor }} “Powered By Speedify” router has been activated</h4>
      <div class="my-2">
        We have automatically added a complimentary 1TB Router license valid for 90 days of service to your account.
      </div>

      <div class="container">
        <div class="row column-gap-3">
          <Button
            label="OK"
            class="col btn btn-speedify-blue stm-btn-cancelation-flow-go-back-1"
            style="flex: 1 1 0"
            @click="close()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue';
import { useRoute } from 'vue-router';

import Button from 'primevue/button';

import { clearUrlHash } from '@/util';

const route = useRoute();
const dialogRef: any = inject('dialogRef');
const hash = new URLSearchParams(route.hash.substring(1));
const vendor = hash.get('vendor');

const close = () => {
  dialogRef.value.close();
  clearUrlHash();
};
</script>

<style type="text/css" media="screen" scoped>
.p-dialog-header {
  padding: 1em 0 0 0 !important;
  border-bottom: 1px solid #e4e7ec;
}

.p-dialog-content {
  padding: 0 0 1em 0 !important;
}

.router-card {
  border: none;
  padding: 1em;
  margin-top: 1em;
}

.p-dialog button {
  border: 1px #f2f4f7 !important;
}

.router-font-grey {
  font-size: 0.8em;
  color: #999999;
}
</style>
