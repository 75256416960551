<template>
  <!-- eslint-disable vuejs-accessibility/anchor-has-content -->
  <footer class="navbar navbar-expand-lg navbar-light bg-light position-absolute w-100 bottom-0">
    <div class="container justify-content-md-end">
      <!-- links -->
      <div id="navbarNav" class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link stm-btn-status"
              href="https://status.speedify.com/"
              target="_blank"
              rel="noopener noreferrer"
            >Status</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link stm-btn-privacy-policy"
              href="https://speedify.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >Privacy Policy</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link stm-btn-tos"
              href="https://speedify.com/terms-of-service/"
              target="_blank"
              rel="noopener noreferrer"
            >Terms of Service</a>
          </li>
        </ul>
      </div>

      <!-- social media -->
      <div class="d-flex width-100 justify-content-end">
        <ul class="navbar-nav flex-row gap-4">
          <span class="navbar-text">Follow us!</span>
          <li class="nav-item">
            <a
              class="nav-link stm-btn-instagram"
              href="https://www.instagram.com/speedify/"
              target="_blank"
              rel="noopener noreferrer"
            ><i class="fab fa-instagram"></i></a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link stm-btn-tiktok"
              href="https://tiktok.com/@speedifylive"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-tiktok"></i>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link stm-btn-twitter"
              href="https://twitter.com/speedify"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link stm-btn-linkedin"
              href="https://www.linkedin.com/showcase/speedify"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link stm-btn-youtube"
              href="https://www.youtube.com/channel/UC5sC63wOQ7kP3fVF3ZhrMmQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-youtube" />
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link stm-btn-twitch"
              href="https://www.twitch.tv/speedify"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-twitch"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
