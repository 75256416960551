<template>
  <div class="d-flex justify-content-between align-items-end my-3">
    <div class="col-lg-6">
      <h3 class="my-2">Users</h3>
      <small>Manage your team's users and their permissions</small>
    </div>
  </div>

  <div class="card mb-4">
    <div class="card-body">
      <DataTable
        :value="teamMembers"
        responsiveLayout="scroll"
        class="p-datatable-sm"
        :paginator="state.tablePaginationEnabled"
        :rows="state.tableRowCount"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 15, 20,50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      >
        <Column field="email" header="E-Mail" :sortable="true" />
        <Column field="usedGB" header="Usage (GB)" :sortable="true">
          <template #body="slotProps">
            {{formatUsedGB(slotProps.data.usedGB)}}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import { useUserStore } from '@/store/user';
import { Subscription } from '@/model';

const props = defineProps<{
  subscription: Subscription,
}>();

const userStore = useUserStore();

const state = reactive({
  tableRowCount: 10,
  tablePaginationEnabled: false,
});

const formatUsedGB = (usedGB: number) => `${usedGB.toFixed(2)} GB`;
const teamMembers = [
  {
    email: props.subscription.ownerEmail,
    usedGB: userStore.usedDataGB,
  },
];
</script>
