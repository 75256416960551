<template>
  <PageNavbar />
  <div class="container">
    <div class="row mt-5 mx-3 mb-3">
      <div class="col-sm-7">
        <div class="card px-5 py-5">
          <h2 class="card-title">{{ title }}</h2>

          <div v-if="!state.success" class=" ">
            <p class="mt-2 mb-3">
              Enter your email address and we will send an email with instructions on how to reset
              your password.
            </p>

            <div class="">
              <form @submit.prevent="sendPasswordResetRequest">
                <div class="form-floating">
                  <input
                    id="emailToReset"
                    v-model="state.emailToReset"
                    type="email"
                    class="form-control my-3"
                    placeholder=""
                    required
                    aria-label="Email to reset"
                    aria-describedby="emailToReset"
                    aria-required="true"
                  />
                  <label for="emailToReset">E-mail</label>
                </div>
                <small v-if="$v.$invalid" style="color: red"
                  >Please enter a valid email</small
                >

                <button
                  type="button"
                  class="btn btn-speedify-blue my-3"
                  :class="{ disabled: $v.$invalid }"
                  @click="sendPasswordResetRequest"
                >
                  Reset Password
                </button>
              </form>
              <router-link to="/login">Sign In</router-link>
            </div>
          </div>

          <div v-if="state.success" class="">
            <div class="mt-2 mb-3">
              <h5>Success.</h5>
            </div>
            <div class="">
              If your email address is on record, you will receive an email with a link to change
              your password in the next few minutes. If you don't receive an email, please check
              your spam folder or contact us at support@speedify.com
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="card pt-3 pb-3 px-5 py-5 h-100">
          <h2 class="card-title mx-auto my-auto">Don't have a Speedify account?</h2>

          <p>
            Enjoy more stable and secure livestreaming, video calling, gaming, and web browsing.
          </p>

          <a
            type="button"
            class="btn btn-speedify-blue mx-auto my-auto"
            href="https://speedify.com/store/"
            target="_blank"
            >Get Started</a
          >
        </div>
      </div>
    </div>
  </div>
  <PageFooter />
</template>

<script lang="ts" setup charset="utf-8">
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

import PageNavbar from '@/components/PageNavbar.vue';
import PageFooter from '@/components/PageFooter.vue';

const route = useRoute();
const { title } = route.meta;
const state = reactive({
  emailToReset: '',
  success: false,
  error: false,
  errorContext: '',
});
const rules = { emailToReset: { required, email } };
const $v = useVuelidate(rules, state);

const sendPasswordResetRequest = async () => {
  const msg =
    'Something went wrong trying to request a password reset. Please try again later or contact support@speedify.com';
  try {
    const response = await fetch(`/api/password/token/${state.emailToReset}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });
    if (response.status !== 200) {
      const responseJson = await response.json();
      state.error = true;
      state.errorContext = responseJson.message || msg;
    } else {
      state.success = true;
    }
  } catch (err) {
    state.error = true;
    state.errorContext = (err as any).message || msg;
  }
};
</script>
