<template>
  <div class="container-fluid">
    <div class="router-card">
      <h4 class="fw-bold">Speedify for Routers 1TB</h4>
      <span class="router-font-grey">
        Activate your “Powered by Speedify” Router and share the bonded super connection with all devices on your network.
      </span>
      <div class="my-2">Includes 1TB of recurring monthly data</div>

      <div class="container">
        <div class="row column-gap-3">
          <Button
            label="$30 Monthly"
            class="col btn btn-speedify-grey stm-btn-cancelation-flow-pause-1"
            style="flex: 1 1 0"
            @click="buy('speedify-pro-router-1000gb-monthly')"
          />
          <Button
            label="$300 Yearly"
            class="col btn btn-speedify-grey stm-btn-cancelation-flow-pause-1"
            style="flex: 1 1 0"
            @click="buy('speedify-pro-router-1000gb-yearly')"
          />
        </div>
      </div>
    </div>

    <div class="router-card">
      <h4 class="fw-bold">Speedify for Routers 3TB</h4>
      <span class="router-font-grey">
        Activate your “Powered by Speedify” Router and share the bonded super connection with all devices on your network.
      </span>
      <br />
      <!-- TODO: link to in-app purchase -->
      <div class="my-2">Includes 3TB of recurring monthly data</div>

      <div class="container">
        <div class="row column-gap-3">
          <Button
            label="$45 Monthly"
            class="col btn btn-speedify-grey stm-btn-cancelation-flow-pause-1"
            style="flex: 1 1 0"
            @click="buy('speedify-pro-router-3000gb-monthly')"
          />
          <Button
            label="$450 Yearly"
            class="col btn btn-speedify-grey stm-btn-cancelation-flow-pause-1"
            style="flex: 1 1 0"
            @click="buy('speedify-pro-router-3000gb-yearly')"
          />
        </div>
      </div>
    </div>

    <div class="router-card">
      <h4 class="fw-bold">Redeem an Activation Code</h4>
      <span class="router-font-grey">Did your purchase a license from a vendor or reseller?</span>

      <div class="container">
        <div class="row column-gap-3">
          <Button
            label="Redeem a Code"
            class="col btn btn-speedify-grey stm-btn-cancelation-flow-pause-1"
            style="flex: 1 1 0"
            @click="redeem()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue';
import { useRouter } from 'vue-router';

import Button from 'primevue/button';
import { useDialog } from 'primevue/usedialog';

import RouterRedeemCode from '@/components/RouterRedeemCode.vue';
import RouterPlanConfirmation from '@/components/RouterPlanConfirmation.vue';
import { clearUrlHash } from '@/util';

const dialog = useDialog();
const router = useRouter();
const dialogRef: any = inject('dialogRef');
const redeem = () => {
  dialogRef.value.close();
  clearUrlHash();
  dialog.open(RouterRedeemCode, {
    props: {
      modal: true,
      draggable: false,
      header: 'Redeem an Activation Code',
    },
  });
};

const buy = async (code: string) => {
  dialogRef.value.close();
  clearUrlHash();

  dialog.open(RouterPlanConfirmation, {
    props: {
      modal: true,
      draggable: false,
      header: 'Please confirm your change',
    },
    onClose: clearUrlHash,
    data: { code },
  });

  void (await router.replace({ ...router.currentRoute, hash: `#router-purchase-plan=${code}` }));
};
</script>

<style type="text/css" media="screen" scoped>
.p-dialog-header {
  padding: 1em 0 0 0 !important;
}

.p-dialog-content {
  padding: 0 0 1em 0 !important;
}

.router-card {
  border: 1px solid #e4e7ec;
  border-radius: 10px;
  margin-top: 1em;
}

.p-dialog button {
  border: 1px #f2f4f7 !important;
}

.router-font-grey {
  font-size: 0.8em;
  color: #999999;
}
</style>
