<template>
  <div
    id="revokeDeviceModal"
    class="modal fade"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="revokeDeviceModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="revokeDeviceModal" class="modal-title">Remove Device</h5>
          <button
            type="button"
            class="btn-close stm-btn-revoke-device-cancel"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="dismissModal"
          />
        </div>
        <div class="modal-body">
          <h5>{{ nickname }}</h5>
          <p>Are you sure you wish to remove this device from your account?</p>
        </div>
        <div
          v-if="state.failed"
          class="alert alert-danger mt-3 stm-msg-revoke-device-error"
          role="alert"
        >
          <i class="fas fa-exclamation-circle"></i>&nbsp;Error: unable to remove device
          <hr />
          {{ state.failureError }}
        </div>
        <div
          v-if="state.success"
          class="alert alert-success mt-3 stm-msg-revoke-device-success"
          role="alert"
        >
          <i class="fas fa-check-circle"></i>&nbsp;Device has been successfully removed!
        </div>
        <div class="modal-footer">
          <button
            v-if="state.success"
            type="button"
            class="btn btn-speedify-blue stm-btn-revoke-device-done"
            data-bs-dismiss="modal"
            @click="dismissModal"
          >
            Done
          </button>
          <button
            v-if="!state.success"
            type="button"
            class="btn btn-speedify-blue stm-btn-revoke-device-confirm"
            :class="{ disabled: state.pending }"
            @click="handleRevokeDeviceClick"
          >
            <span
              v-if="state.pending"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            Remove Device
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { revokeLinkedDevice } from '@/services/backend';
import { useUserStore } from '@/store/user';

const userStore = useUserStore();

const props = defineProps<{
  nickname: string | null,
  uuid: string,
}>();

const emit = defineEmits(['getLinkedDevices', 'dismissModal']);

const state = reactive({
  pending: false,
  success: false,
  failed: false,
  failureError: {},
});

const handleRevokeDeviceClick = async () => {
  state.pending = true;
  const response = await revokeLinkedDevice(props.uuid);
  if (response.error) {
    state.success = false;
    state.failed = true;
    state.failureError = response.message;
  } else {
    state.failed = false;
    state.success = true;
    userStore.removeActiveDevice(props.uuid);
    emit('getLinkedDevices');
  }
  state.pending = false;
};

const dismissModal = () => emit('dismissModal');
</script>
