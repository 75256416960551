<template>
  <!-- Team member removal modal -->
  <div
    v-if="state.showTeamMemberRemovalModal"
    id="teamMemberRemovalModal"
    class="modal fade"
    data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="teamMemberRemovalLabel" aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="teamMemberRemovalLabel" class="modal-title">Please confirm</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="dismissTeamMemberRemovalRequest()"></button>
        </div>
        <div class="modal-body">
          <div v-if ="!state.memberRemoval.success">
          Are you sure you want to remove {{ state.memberRemoval.email }} from your {{ teamType }}?
          </div>
          <!-- alerts -->
          <div v-if="state.memberRemoval.error" class="alert alert-danger stm-msg-team-member-removal-error" role="alert">
            Something went wrong when removing the user from your {{ teamType }}.
             Please try again or contact support@speedify.com
            <hr>
            {{ state.memberRemoval.errorContext }}
          </div>

          <div v-if="state.memberRemoval.success" class="alert alert-success stm-msg-team-member-removal-success" role="alert">
            {{ state.memberRemoval.email }} was removed from the {{ teamType }}.
          </div>
        </div>

        <div class="modal-footer">
          <!-- actions -->
          <button
            v-if="state.memberRemoval.success"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="dismissTeamMemberRemovalRequest()"
          >
            Done
          </button>
          <button
            v-if="!state.memberRemoval.success"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="dismissTeamMemberRemovalRequest()"
          >
            No
          </button>
          <button
            v-if="!state.memberRemoval.success"
            type="button"
            class="btn btn-danger"
            @click="removeMemberFromTeam()"
          >
            <span v-if="state.memberRemoval.pending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            Yes, I confirm
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-4">
    <div class="card-header">
      <!-- eslint-disable vuejs-accessibility/form-control-has-label -->
      <input v-model="state.query" type="email" class="form-control" placeholder="Search by E-Mail">
      <!-- eslint-enable vuejs-accessibility/form-control-has-label -->
    </div>
    <div class="card-body">
      <DataTable
        :value="teamMembers"
        responsiveLayout="scroll"
        class="p-datatable-sm"
        :paginator="state.tablePaginationEnabled"
        :rows="state.tableRowCount"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 15, 20,50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      >
        <Column field="email" header="E-Mail" :sortable="true" />
        <Column field="role" header="Role" :sortable="true">
          <template #body="slotProps">
            {{formatTeamRole(slotProps.data.role)}}
          </template>
        </Column>
        <Column field="dateGenerated" header="Date Added" :sortable="true">
          <template #body="slotProps">
            {{formatDate(slotProps.data.dateGenerated)}}
          </template>
        </Column>
        <Column field="usedGB" header="Usage (GB)" :sortable="true">
          <template #body="slotProps">
            {{formatUsedGB(slotProps.data.usedGB)}}
          </template>
        </Column>

        <!-- actions -->
        <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
          <template #body="slotProps">
            <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
            <FlatGarbageButtonRed
              v-if="!isOwnerOrSelf(slotProps.data.email)"
              @click="registerTeamMemberRemovalRequest(slotProps.data.email); handleShowModal('teamMemberRemovalModal')"
            />
            <!-- Elements with the disabled attribute aren’t interactive,
              meaning users cannot focus, hover, or click them to trigger
              a tooltip (or popover). As a workaround, you’ll want to
              trigger the tooltip from a wrapper <div> or <span>
            -->
            <div v-else data-bs-toggle="tooltip" data-bs-placement="top" title="Unable to remove the team's owner or yourself from the team">
              <FlatGarbageButtonDisabledGrey />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import { removeTeamMember } from '@/services/backend';
import { useUserStore } from '@/store/user';
import FlatGarbageButtonRed from '@/components/buttons/FlatGarbageButtonRed.vue';
import FlatGarbageButtonDisabledGrey from '@/components/buttons/FlatGarbageButtonDisabledGrey.vue';
import { showModal } from '@/services/modal';
import { Subscription, Role } from '@/model';

const props = defineProps<{
  subscription: Subscription,
  referenceId: string,
  teamType: string,
}>();

const userStore = useUserStore();

const state = reactive({
  query: '',
  memberRemoval: {
    email: '',
    success: false,
    error: false,
    errorContext: '',
    pending: false,
  },
  tableRowCount: props.subscription.type === 'families' ? 5 : 10,
  tablePaginationEnabled: props.subscription.type !== 'families',
  showTeamMemberRemovalModal: false,
});

const formatUsedGB = (usedGB: number) => `${usedGB.toFixed(2)} GB`;
const formatTeamRole = (role: Role) => (props.subscription.type === 'families' && role === 'user' ? 'member' : role);
const formatDate = (date: any) => new Date(date).toDateString();
const teamMembers = computed(() => props.subscription.team!.teamMembers.filter((m) => m.email.toLowerCase().match(
  state.query.replace(/\+/, '\\+'),
)));

const dismissTeamMemberRemovalRequest = () => {
  state.memberRemoval.email = '';
  state.memberRemoval.success = false;
  state.memberRemoval.error = false;
  state.memberRemoval.errorContext = '';
  state.memberRemoval.pending = false;
  state.showTeamMemberRemovalModal = false;
};

const registerTeamMemberRemovalRequest = (email: string) => {
  state.memberRemoval.email = email;
};

const isOwnerOrSelf = (email: string) => [
  props.subscription.ownerEmail.toLowerCase(),
  userStore.email.toLocaleLowerCase()
].includes(email.toLowerCase());

const removeMemberFromTeam = async () => {
  state.memberRemoval.pending = true;

  const response = await removeTeamMember(props.referenceId, state.memberRemoval.email);
  if (response.error) {
    state.memberRemoval.error = false;
    return;
  }
  state.memberRemoval.success = true;
  state.memberRemoval.pending = false;

  userStore.removeTeamMember(props.referenceId, state.memberRemoval.email);
};

const handleShowModal = (elementID: string) => {
  // @ts-ignore
  state[`show${elementID.charAt(0).toUpperCase()}${elementID.slice(1)}`] = true;
  showModal(elementID);
};
</script>
