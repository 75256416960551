<template>
  <Dialog
    v-model:visible="visible"
    modal
    :closable="false"
    :draggable="false"
    :style="{ width: '50vw' }"
    header="If you cancel, you'll miss out on"
    pt:title:class="fw-bold white-space-nowrap mx-auto mt-4 fs-4"
  >
    <div class="container">
      <div class="row align-items-center">
        <img class="col-3" src="/images/cool-guy-sitting-with-phone.png" />
        <div class="col-9">
          <span class="fw-bold">Unlimited Usage</span>
          <p class="m-0">Enjoy unlimited access to Speedify</p>
        </div>
      </div>

      <div v-if="subscription.type !== 'teams'" class="row align-items-center">
        <img class="col-3" src="/images/guy-with-spanner-rack.png" />
        <div class="col-9">
          <span class="fw-bold">Premium Server Access</span>
          <p class="m-0">Access additional servers in more than 50 locations worldwide</p>
        </div>
      </div>

      <div v-if="subscription.type === 'teams'" class="row align-items-center">
        <img class="col-3" src="/images/guy-with-spanner-rack.png" />
        <div class="col-9">
          <span class="fw-bold">Premium & Dedicated Server Access</span>
          <p class="m-0">
            Access additional servers in more than 50 locations worldwide. Purchase Dedicated Servers for consistent IPs, port
            forwarding, and more!
          </p>
        </div>
      </div>

      <div v-if="subscription.type === 'individual'" class="row align-items-center">
        <img class="col-3" src="/images/guy-with-mobile-devices.png" />
        <div class="col-9">
          <span class="fw-bold">Multiple Devices</span>
          <p class="m-0">Use on up to 5 devices simultaneously on your account</p>
        </div>
      </div>

      <div v-if="subscription.type === 'families'" class="row align-items-center">
        <img class="col-3" src="/images/people-with-mobile-devices.png" />
        <div class="col-9">
          <span class="fw-bold">User Accounts for You and 4 Family Members</span>
          <p class="m-0">Use each account on up to 5 devices simultaneously</p>
        </div>
      </div>

      <div v-if="subscription.type === 'teams'" class="row align-items-center">
        <img class="col-3" src="/images/three-people-global-network.png" />
        <div class="col-9">
          <span class="fw-bold">Centralized Account Management</span>
          <p class="m-0">Add or remove seats as needed and use each on up to 5 devices simultaneously</p>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="container mb-4">
        <div class="row column-gap-3">
          <Button
            label="Nevermind, Go Back"
            class="col btn btn-speedify-grey stm-btn-cancelation-flow-go-back-1"
            style="flex: 1 1 0"
            @click="showModal(null)"
          />
          <!-- TODO: pause -->
          <Button
            v-if="false"
            label="Pause Subscription"
            class="col btn btn-speedify-grey stm-btn-cancelation-flow-pause-1"
            style="flex: 1 1 0"
            @click="showModal('Pause')"
          />

          <Button
            label="Cancel Subscription"
            class="btn btn-speedify-blue stm-btn-cancelation-flow-cancel-1"
            style="flex: 1 1 0"
            @click="clickCancel"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import { useUserStore } from '@/store/user';
import { modal } from '@/symbol';
import { getSeats } from '@/services/subscription';
import { getCouponForSubscription } from '@/services/backend';

const route = useRoute();
const { referenceId } = route.query as { [key: string]: string };
const { subscriptions } = useUserStore();
const subscription = computed(() => subscriptions.find(s => s.referenceId === referenceId)!);

const { currModal, showModal } = inject(modal)!;
const visible = computed(() => currModal.value === 'Perks');
const redeemed = ref(false);

onMounted(async () => {
  // TODO: cache this call?
  const coupon = await getCouponForSubscription(subscription.value.subscriptionId);
  redeemed.value = coupon.redeemed;
});

const clickCancel = () => {
  const sub = subscription.value;

  const isDedicatedServer = !!sub.productName.match(/dedicated/i);
  if (isDedicatedServer) return showModal('AreYouSure');

  const isTeam = !!sub.productName.match(/team/i);
  const { team, quantity } = sub;
  const hasInactiveSeats = isTeam && !!getSeats({ team: team!, quantity }).inactive;
  if (isTeam) return showModal(hasInactiveSeats ? 'RemoveInactiveSeats' : 'AreYouSure');

  const canOffer = sub.cancelable && sub.productName.match(/(monthly|yearly)/i) && !redeemed.value;
  return canOffer ? showModal('Offer') : showModal('AreYouSure');
};
</script>
