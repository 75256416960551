<template>
  <Dialog
    v-model:visible="visible"
    modal
    :closable="false"
    :draggable="false"
    :style="{ width: '50vw' }"
    pt:header:class="justify-content-center"
  >
    <template #header>
      <img src="/images/guy-with-spanner.png" style="max-width: 50%" />
    </template>

    <div class="row text-center">
      <h4>Cancel your Speedify Plan?</h4>
      <p>
        Access to your Speedify plan and premium features will expire on
        <span style="color: red">{{ renewalDate }}</span>
        .
      </p>
    </div>

    <template #footer>
      <div class="container mb-4">
        <div class="row column-gap-3">
          <Button
            label="Nevermind, Go Back"
            class="btn btn-speedify-grey stm-btn-cancelation-flow-go-back-3"
            style="flex: 1 1 0"
            @click="showModal(null)"
          />
          <Button
            label="Confirm Cancellation"
            class="btn btn-speedify-blue stm-btn-cancelation-flow-cancel-confirm"
            style="flex: 1 1 0"
            @click="cancelSubscription"
          ></Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { useRoute } from 'vue-router';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import { cancelSubscriptionByReferenceId } from '@/services/backend';
import { useUserStore } from '@/store/user';
import { modal } from '@/symbol';
import { shortRenewalSummary as subscriptionRenewalDate } from '@/services/subscription';

let { subscriptions } = useUserStore();

const subscription = computed(() => subscriptions.find(s => s.referenceId === referenceId)!);
const renewalDate = computed(() => subscriptionRenewalDate(subscription.value).toLocaleDateString());
const route = useRoute();
const referenceId = route.query.referenceId as string;

const emit = defineEmits<{
  'tried-cancellation': [result: 'success' | 'failure', message?: string | Error];
}>();

const { currModal, showModal } = inject(modal)!;
const visible = computed(() => currModal.value === 'AreYouSure');

const cancelSubscription = async () => {
  const response = await cancelSubscriptionByReferenceId(referenceId);
  if (response.error) {
    emit('tried-cancellation', 'failure', response.message);
    return showModal(null);
  }

  subscriptions.find(s => s.referenceId === referenceId)!.cancelable = 0;
  emit('tried-cancellation', 'success');
  showModal(null);
};
</script>
