<template>
  <div class="card my-3 p-3">
    <div class="card-body">
      <div class="card-title">Apple App Store</div>
        <p>
          Please <a href="https://apps.apple.com/account/subscriptions">click here</a>
          to view your subscription information, invoices, and information about cancellation on the Apple App Store
        </p>
    </div>
  </div>
</template>
