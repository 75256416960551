<template>
  <span class="p-3">Enter the activation code from your device</span>
  <VOtpInput
    ref="input"
    v-model:value="code"
    class="p-3"
    separator=""
    :num-inputs="CODE_LENGTH"
    :should-auto-focus="true"
    input-type="tel"
    :input-classes="['otp-input', conditional]"
    @on-change="result = null"
    @on-complete="handleOnComplete"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import VOtpInput from 'vue3-otp-input';
import { useRoute, LocationQueryValue } from 'vue-router';

import * as Sentry from '@sentry/vue';
import { getToken, linkDevice, isError } from '@/services/backend';

const route = useRoute();
const CODE_LENGTH = 6;
const activationCode = route.query.activationCode as LocationQueryValue ?? '';
const isFromApp = route.query.fromapp === '1';
const input = ref<InstanceType<typeof VOtpInput> | null>(null);
const code = ref('');
const result = ref<'Success' | 'Failure' | 'Error' | null>(null);
const conditional: any = computed(() => ({
  success: code.value.length === CODE_LENGTH && result.value === 'Success',
  failure: code.value.length === CODE_LENGTH && result.value === 'Failure',
}));
const emit = defineEmits<{
  'tried-activation': [result: 'Failure' | 'Error' | 'Success', code: string, deviceUUID?: string]
}>();

const handleOnComplete = async (code: string) => {
  const token = await getToken();
  if (!token) {
    const cookies = document.cookie.split('; ').map(v => v.split(/=(.*)/s).map(decodeURIComponent));
    const msg = 'Unable to get a valid token from document.cookie';
    Sentry.captureException(
      new Error(msg),
      { extra: { tok: Object.fromEntries(cookies).token }},
    );
    return emit('tried-activation', 'Error', code);
  }

  const resp = await linkDevice(token.userid, code);

  if (isError(resp)) {
    result.value = resp.status === 404 ? 'Failure' : 'Error';
  } else {
    result.value = 'Success';
  }
  emit('tried-activation', result.value, code, resp.deviceUUID);
};

// Auto fill the input boxes if the URL has an activation code already
onMounted(async () => {
  if (activationCode && isFromApp) {
    await handleOnComplete(activationCode);
  } else if (activationCode) {
    input?.value?.fillInput(activationCode);
  }
});
</script>

<style lang="css">
.otp-input {
  width: 35px;
  height: 40px;
  padding: 4px;
  margin: 0 5px;
  font-size: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  text-align: center;
}
.otp-input.is-complete {
  background-color: #e4e4e4;
}
.otp-input.failure {
  border: 1px solid red !important;
}
.otp-input.success {
  border: 1px solid green !important;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
