<template>
  <div class="card my-3 p-3">
    <div class="card-body">
      <div class="card-title">Google Play Store</div>
        <p>
          Please <a href="https://play.google.com/store/account/subscriptions?package=com.speedify.speedifyandroid">click here</a>
          to view your subscription information, invoices, and information about cancellation on the Google Play Store
        </p>
    </div>
  </div>
</template>
