<template>
  <div v-for="(instance, key) in instanceMap" :key="key">
    <component
      :is="instance.content"
      v-if="instance.visible"
      v-bind="{ ...instance.options.props, ...instance.options.emits }"
      @hide="onBannerHide(instance)"
      @after-hide="onBannerAfterHide"
    ></component>
  </div>
</template>

<script setup lang="ts">
import BaseComponent from '@primevue/core/basecomponent';
import { UniqueComponentId } from '@primevue/core/utils';
import EventBus from './bannereventbus';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import type { Ref } from 'vue';

export interface BannerOptions {
  props?: any;
  data?: any;

  [key: string]: any;
}

export interface BannerInstance {
  content: any;
  options: BannerOptions;
  data: any;
  close: (params?: any) => void;
  visible: boolean;
  key: string;
}

defineOptions({
  name: 'Banner', // eslint-disable-line vue/multi-word-component-names
  extends: BaseComponent,
  provide: () => ({ // TODO:
    $pcBanner: undefined,
    $parentInstance: undefined,
  }),
  inheritAttrs: false,
});

const instanceMap: Ref<{ [key: string]: BannerInstance }> = ref({});
let openListener: ({ instance }: { instance: BannerInstance }) => void;
let closeListener: ({ instance, params }: { instance: BannerInstance; params: any }) => void;
let currentInstance: BannerInstance | null = null;

onMounted(() => {
  openListener = ({ instance }: any) => {
    const key = UniqueComponentId() + '_banner';

    instance.visible = true;
    instance.key = key;
    instanceMap.value[key] = instance;
  };

  closeListener = ({ instance, params }) => {
    const key = instance.key;
    const curr = instanceMap.value[key];

    if (curr) {
      curr.visible = false;
      const onClose = curr.options.onClose;
      onClose && onClose({ data: params, type: 'config-close' });

      currentInstance = curr;
    }
  };

  EventBus.on('open', openListener);
  EventBus.on('close', closeListener);
});

onBeforeUnmount(() => {
  EventBus.off('open', openListener);
  EventBus.off('close', closeListener);
});

const onBannerHide = (instance: BannerInstance) => {
  !currentInstance && instance.options.onClose && instance.options.onClose({ type: 'banner-close' });
};
const onBannerAfterHide = () => (currentInstance = null);

defineExpose({
  onBannerHide,
  onBannerAfterHide,
});
</script>
