import { useUserStore } from '@/store/user';
import { getToken } from './backend';

const cartUrl: string = import.meta.env.VITE_CART_URL;

export const hasValidUser = async () => {
  const store = useUserStore();
  if (store.userid === 0) return false;

  const token = await getToken();
  return token?.userid === store.userid;
};

export const isUserAnOwner = (referenceId: string) => {
  const { email, subscriptions } = useUserStore();

  const relatedSubscription = subscriptions.find((s) => s.referenceId === referenceId);
  if (!relatedSubscription) {
    return false;
  }

  if (email === relatedSubscription.ownerEmail) {
    return true;
  }

  return false;
};

export const isUserAdminOrOwner = (referenceId: string) => {
  const { email, subscriptions } = useUserStore();

  const relatedSubscription = subscriptions.find((s) => s.referenceId === referenceId);
  if (!relatedSubscription) {
    return false;
  }

  if (email === relatedSubscription.ownerEmail) {
    return true;
  }

  if (relatedSubscription.type === 'teams' || relatedSubscription.type === 'families') {
    const relatedMember = relatedSubscription.team!.teamMembers.find((m) => m.email === email);
    if (!relatedMember) {
      return false;
    }
    if (relatedMember.role === 'admin') {
      return true;
    }
  }

  return false;
};

export const loginRedirect = (redirect: string) => {
  const url = new URL(redirect, window.location.origin)

  if (url.origin === window.location.origin) return url.toString().substring(url.origin.length);
  // for now we only expect that only the new cart is asking for outbound redirection on logins,
  // so restrict this to prevent reflected XSS attacks
  if (url.origin === cartUrl) {
    window.location.replace(url);
  }

  return '/unreachable';
};
