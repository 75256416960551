<template>
  <nav class="navbar navbar-expand-lg navbar-light py-3">
    <div class="container">
      <router-link class="navbar-brand logo-container" :to="{ name: 'AccountDashboard' }">
        <SpeedifyLogo />
      </router-link>
      <div v-if="show" class="d-flex">
        <a type="button" class="btn btn-ghost-dark me-2" aria-current="page" @click="logout">LOG OUT</a>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import wretch from 'wretch';
import SpeedifyLogo from './SpeedifyLogo.vue';
import { isAuthenticated } from '@/services/backend';
import { useUserStore } from '@/store/user';

const userStore = useUserStore();
const router = useRouter();

const { showLogout } = withDefaults(defineProps<{ showLogout?: boolean }>(), { showLogout: true });

const show = ref(showLogout || useRoute().query.fromapp === '1');

onBeforeMount(async () => {
  if (showLogout === undefined && await isAuthenticated()) show.value = false;
});

const logout = () =>
// `userStore.reset` is not enough if the user manually vists /logout
// which invalidates cookies but keeps userStore in the browser memory
// userStore will be wrong if a different user is logged in.
// So, we have additional checks in `hasValidUser`
  wretch('/logout')
    .get()
    .res()
    .catch(console.error)
    .then(() =>userStore.reset())
    .then(() => router.replace('/login'));
</script>
