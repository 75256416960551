import { inject } from 'vue';

import { BannerInstance, BannerOptions } from '@/components/Banner.vue';
export const PrimeVueBannerSymbol = Symbol();

export function useBanner() {
    type Banner = { open: (content: any, options: BannerOptions) => BannerInstance };
    const PrimeVueBanner = inject(PrimeVueBannerSymbol);

    if (!PrimeVueBanner) {
        throw new Error('No Banner provided!');
    }

    return PrimeVueBanner as Banner;
}
