<template>
  <!-- API Key revocation modal -->
  <div
    v-if="state.showApiKeyRevocationModal"
    id="apiKeyRevocationModal"
    class="modal fade"
    data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="apiKeyRevocationLabel" aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="apiKeyRevocationLabel" class="modal-title">Please confirm</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="dismissRevocationRequest()"></button>
        </div>
        <div class="modal-body">
          Are you sure you want to disable this API Key?

          <!-- alerts -->
          <div v-if="state.revocationError" class="alert alert-danger stm-msg-api-key-revocation-error" role="alert">
            Something went wrong when revoking the API Key. Please try again or contact support@speedify.com
            <hr>
            {{ state.revocationErrorContext }}
          </div>

          <div v-if="state.success" class="alert alert-success stm-msg-api-key-revocation-success" role="alert">
            API Key was revoked
          </div>
        </div>

        <div class="modal-footer">
          <!-- actions -->
          <button
            v-if="state.success"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="dismissRevocationRequest()"
          >
            Done
          </button>
          <button
            v-if="!state.success"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="dismissRevocationRequest()"
          >
            No
          </button>
          <button v-if="!state.success" type="button" class="btn btn-danger" @click="revokeSelectedAPIKey()">Yes, I confirm</button>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <DataTable
      :value="apiKeys"
      responsiveLayout="scroll"
      class="p-datatable-sm"
      :paginator="true"
      :rows="5"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    >
      <!-- font-monospace comes from bootstrap -->
      <Column class="font-monospace" field="apiKey" header="API Key" />
      <Column field="description" header="Description" />
      <Column field="dateGenerated" header="Generated" :sortable="true">
        <template #body="slotProps">
          {{formatDate(slotProps.data.dateGenerated)}}
        </template>
      </Column>

      <!-- actions -->
      <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
          <FlatGarbageButtonRed
            @click="registerAPIKeyRevocationRequest(slotProps.data.apiKey)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { reactive } from 'vue';

import { showModal } from '@/services/modal';
import { revokeAPIKey } from '@/services/backend';
import { useUserStore } from '@/store/user';
import FlatGarbageButtonRed from '@/components/buttons/FlatGarbageButtonRed.vue';
import { ApiKey } from '@/model';

const props = defineProps<{
  apiKeys: ApiKey[],
  referenceId: string,
}>();

const state = reactive({
  showModal: false,
  apiKey: '',
  pendingRevocation: false,
  revocationError: false,
  revocationErrorContext: '',
  success: false,
  showApiKeyRevocationModal: false,
});
const userStore = useUserStore();

const handleShowModal = (elementID: string) => {
  // @ts-ignore
  state[`show${elementID.charAt(0).toUpperCase()}${elementID.slice(1)}`] = true;
  showModal(elementID);
};

const formatDate = (d: any) => new Date(d).toDateString();

const registerAPIKeyRevocationRequest = (apiKey: string) => {
  state.apiKey = apiKey;
  state.pendingRevocation = true;
  handleShowModal('apiKeyRevocationModal');
};

const dismissRevocationRequest = () => {
  state.showModal = false;
  state.apiKey = '';
  state.pendingRevocation = false;
  state.revocationError = false;
  state.revocationErrorContext = '';
  state.success = false;
};

const revokeSelectedAPIKey = async () => {
  try {
    const result = await revokeAPIKey(props.referenceId, state.apiKey);
    if (result.error) {
      // @ts-ignore
      throw result.response;
    }

    userStore.revokeAPIKeyFromSubscription(props.referenceId, state.apiKey);
    state.success = true;
  } catch (err) {
    state.revocationError = true;
    state.revocationErrorContext = err as string;
  }
};
</script>
