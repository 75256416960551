import EventBus from './bannereventbus';
import { PrimeVueBannerSymbol } from './usebanner';
import { markRaw } from 'vue';
import type { App } from 'vue';

export default {
  install: (app: App<Element>) => {
    const BannerService = {
      open: (content?: any, options?: any) => {
        const instance = {
          content: content && markRaw(content),
          options: options || {},
          data: options && options.data,
          close: (params: any) => EventBus.emit('close', { instance, params }),
        };

        EventBus.emit('open', { instance })

        return instance;
      }
    };

    app.config.globalProperties.$banner = BannerService;
    app.provide(PrimeVueBannerSymbol, BannerService);
  },
}
