/* typescript-eslint-disable import/prefer-default-export */

import { nextTick } from 'vue';
import { Modal } from 'bootstrap';

export const showModal = (elementID: string) => {
  void nextTick(() => {
    const myModalEl = document.getElementById(elementID);
    const modal = new Modal(myModalEl!, { backdrop: 'static', keyboard: false });
    modal.show();
  });
};
