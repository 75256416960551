<template>
  <div v-if="route.query.utm_redirect" class="container mt-5">
    <div class="alert alert-success alert-dismissable fade show d-flex justify-content-between" role="alert">
      Success! You may manage your subscription and account details here.
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
const route = useRoute();
</script>
