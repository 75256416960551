<template>
  <!-- add api key modal -->
  <div
    v-if="state.showAddAPIKeyModal"
    id="addAPIKeyModal"
    class="modal fade"
    data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addAPIKeyModalLabel" aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="addAPIKeyModalLabel" class="modal-title">Add an API Key for your team</h5>
          <button
            type="button"
            class="btn-close stm-btn-add-api-key-cancel"
            data-bs-dismiss="modal" aria-label="Close"
            @click="dismissNewApiKeyModal()"
          />
        </div>
        <div class="modal-body">
          <form v-if="!state.newAPIKey.success" @submit.prevent="createNewAPIKey">
            <div class="form-floating mb-3">
              <input id="apikeydesc" v-model="state.newAPIKey.description" type="text" maxlength="50" class="form-control" placeholder="">
              <!-- eslint-disable vuejs-accessibility/label-has-for -->
              <label for="apikeydesc">Description for new API Key (50 chars max)</label>
            </div>
          </form>
          <div v-if="state.newAPIKey.createError" class="alert alert-danger stm-msg-add-api-key-failed" role="alert">
            <i class="fas fa-exclamation-circle"></i>&nbsp;Unable to create a new API Key. Please try again or contact support@speedify.com
            <hr>
            {{ state.newAPIKey.errorContext }}
          </div>
          <div v-if="state.newAPIKey.success" class="alert alert-success stm-msg-add-api-key-success" role="alert">
            <i class="fa-solid fa-circle-check"></i>&nbsp;API Key successfully added!
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            :class="{
              'stm-btn-add-api-key-cancel': !state.newAPIKey.success,
              'stm-btn-add-api-key-done': state.newAPIKey.success,
            }"
            data-bs-dismiss="modal"
            @click="dismissNewApiKeyModal()"
          >
            {{ state.newAPIKey.success ? 'Done' : 'Cancel' }}
          </button>
          <button
            v-if="!state.newAPIKey.buttonHidden"
            type="button"
            class="btn btn-speedify-blue stm-btn-add-api-key-confirm"
            :disabled="state.newAPIKey.buttonDisabled"
            @click="createNewAPIKey()"
          >
            <span v-if="state.newAPIKey.pending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            OK
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- api keys -->
  <div class="d-flex justify-content-between align-items-end mb-3">
    <div class="col-lg-6">
      <h3 class="my-2">API Keys</h3>
      <small>Query information and manage users on your Speedify team.</small>
    </div>
    <div class="col-lg-6 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-speedify-blue stm-btn-add-api-key"
        @click="handleShowModal('addAPIKeyModal')"
      >
        <i class="fas fa-plus"></i>&nbsp;Add API Key
      </button>
    </div>
  </div>

  <APIKeyTable :apiKeys="apiKeys" :referenceId="referenceId" />
</template>

<script setup lang="ts">
import { reactive } from 'vue';

import { useUserStore } from '@/store/user';
import APIKeyTable from '@/components/APIKeyTable.vue';
import { createNewAPIKeyForTeam } from '@/services/backend';
import { showModal } from '@/services/modal';
import { ApiKey } from '@/model';

const props = defineProps<{
  apiKeys: ApiKey[],
  referenceId: string,
}>();

const user = useUserStore();
const state = reactive({
  newAPIKey: {
    description: '',
    createError: false,
    errorContext: '',
    pending: false,
    success: false,
    buttonDisabled: false,
    buttonHidden: false,
  },
  showAddAPIKeyModal: false,
});

const dismissNewApiKeyModal = () => {
  state.newAPIKey.description = '';
  state.newAPIKey.createError = false;
  state.newAPIKey.pending = false;
  state.newAPIKey.buttonDisabled = false;
  state.newAPIKey.buttonHidden = false;
  state.newAPIKey.success = false;
  state.newAPIKey.errorContext = '';
  state.showAddAPIKeyModal = false;
};

const createNewAPIKey = async () => {
  state.newAPIKey.buttonDisabled = true;
  state.newAPIKey.pending = true;
  try {
    const result = await createNewAPIKeyForTeam(props.referenceId, state.newAPIKey.description);
    if (result.error) {
      state.newAPIKey.createError = true;
    } else {
      user.appendAPIKeyToSubscription(
        props.referenceId,
        // @ts-expect-error response undefined
        result.response.apiKey,
        state.newAPIKey.description,
      );
      state.newAPIKey.success = true;
    }
  } catch (err) {
    console.error(err);
    state.newAPIKey.createError = true;
    // @ts-ignore
    state.newAPIKey.errorContext = err.message || err;
  }

  state.newAPIKey.pending = false;
  state.newAPIKey.buttonHidden = true;
};

const handleShowModal = (elementID: string) => {
// @ts-ignore
  state[`show${elementID.charAt(0).toUpperCase()}${elementID.slice(1)}`] = true;
  showModal(elementID);
}
</script>
