<template>
  <div class="container mt-5">
    <nav aria-label="breadcrumb my-3">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{ name: 'AccountDashboard' }">Dashboard</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Your Speedify Subscription</li>
      </ol>
    </nav>
    <h1 class="mb-3">Your Speedify Subscription</h1>

    <div class="row my-2">
      <div class="col-sm">
        <div class="card mb-3">
          <div class="card-body pb-4">
            <div class="card-title">
              <small>Owner</small>
            </div>
            <h5>{{ subscription!.ownerEmail }}</h5>
          </div>
        </div>
      </div>
      <div class="col-sm mb-3">
        <div id="email" class="card">
          <div class="card-body pb-4">
            <div class="card-title">
              <small>Renews on</small>
            </div>
            <h5>{{ new Date(subscription!.nextPeriodDate!).toDateString() }}</h5>
          </div>
        </div>
      </div>
    </div>

    <TeamSeats :subscription="subscription" />

    <TeamMemberComponent :subscription="subscription" />

    <DedicatedServers :subscription="subscription" />

    <TeamRouterAssociation />

    <APIKeyComponent
      v-if="subscription!.type === 'teams'"
      :apiKeys="subscription!.team!.apiKeys"
      :referenceId="referenceId as string"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useUserStore } from '@/store/user';
import TeamMemberComponent from '@/components/TeamMemberComponent.vue';
import APIKeyComponent from '@/components/APIKeyComponent.vue';
import TeamSeats from '@/components/TeamSeats.vue';
import DedicatedServers from '@/components/DedicatedServers.vue';
import { Subscription } from '@/model';
import TeamRouterAssociation from '@/components/TeamRouterAssociation.vue';

const route = useRoute();
const { referenceId } = route.query;
const userStore = useUserStore();

const subscription = computed<Subscription>(
  () => userStore.subscriptions.find(s => s.referenceId === referenceId)!,
);
</script>
