<template>
  <div class="container-fluid">
    <div class="router-card">
      <h4 class="fw-bold">{{ plan?.title }}</h4>
      <span class="router-font-grey">{{ plan?.price }}</span>
      <div class="my-2">{{ plan?.desc }}</div>
    </div>

    <div class="container mt-1">
      <div class="row column-gap-3">
        <Button
          label="Go Back"
          class="col btn btn-speedify-grey stm-btn-cancelation-flow-go-back-1"
          style="flex: 1 1 0"
          @click="back()"
        />
        <a
          class="col btn btn-speedify-blue stm-btn-router-1TB-monthly"
          :href="`${cartUrl}/checkout?plan=${plan?.code}&quantity=1`"
          target="_blank"
        >
          Proceed to Checkout
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, markRaw, inject } from 'vue';
import { addMonths, addYears } from 'date-fns';
import { useDialog } from 'primevue/usedialog';

import { clearUrlHash } from '@/util';
import RouterPlansPricing from './RouterPlansPricing.vue';
import RouterPlansPricingHeader from './RouterPlansPricingHeader.vue';
import Button from 'primevue/button';

const dialog = useDialog();
const dialogRef: any = inject('dialogRef');
const cartUrl: string = import.meta.env.VITE_CART_URL;

const back = () => {
  dialogRef.value.close();
  dialog.open(RouterPlansPricing, {
    props: {
      modal: true,
      draggable: false,
    },
    templates: { header: markRaw(RouterPlansPricingHeader) },
    onClose: clearUrlHash,
  });
};

const plan = computed(() => {
  const hash = new URLSearchParams(window.location.hash.substring(1));
  const code: string = hash.get('router-purchase-plan') ?? dialogRef.value.data.code ?? '';

  const fmtShortWeekday = new Intl.DateTimeFormat('en-US', { weekday: 'short' });
  const fmtLongMonth = new Intl.DateTimeFormat('en-US', { month: 'long' });
  const fn = code.includes('monthly') ? addMonths : addYears;
  const date = fn(new Date(), 1);

  const formatted = `${fmtShortWeekday.format(date)} ${fmtLongMonth.format(date)} ${date.getDate()}, ${date.getFullYear()}`;

  return {
    'speedify-pro-router-3000gb-monthly': {
      title: 'Speedify for Routers 3TB - Monthly',
      price: '$45 billed every month',
      desc: `You will be billed $45 now, then $45 every month starting on ${formatted}.`,
      code,
    },
    'speedify-pro-router-3000gb-yearly': {
      title: 'Speedify for Routers 3TB - Yearly',
      price: '$450 billed every year',
      desc: `You will be billed $450 now, then $450 every year starting on ${formatted}.`,
      code,
    },
    'speedify-pro-router-1000gb-monthly': {
      title: 'Speedify for Routers 1TB - Monthly',
      price: '$30 billed every month',
      desc: `You will be billed $30 now, then $30 every month starting on ${formatted}.`,
      code,
    },
    'speedify-pro-router-1000gb-yearly': {
      title: 'Speedify for Routers 1TB - Yearly',
      price: '$300 billed every year',
      desc: `You will be billed $300 now, then $300 every year starting on ${formatted}.`,
      code,
    },
  }[code]!;
});
</script>

<style type="text/css" media="screen" scoped>
.p-dialog-header {
  padding: 1em 0 0 0 !important;
  border-bottom: 1px solid #e4e7ec;
}

.p-dialog-content {
  padding: 0 0 1em 0 !important;
}

.router-card {
  border: 1px solid #e4e7ec;
  border-radius: 10px;
  padding: 1em;
  margin-top: 1em;
}

.p-dialog button {
  border: 1px #f2f4f7 !important;
}

.router-font-grey {
  font-size: 0.8em;
  color: #999999;
}
</style>
